import 'lazysizes'

import Alpine from 'alpinejs'
window.Alpine = Alpine

import {
    createPopper
} from '@popperjs/core'

import {
    Fancybox
} from '@fancyapps/ui'

import {
    bootstrap
} from 'bootstrap'

import formable from './interactsWithContactForm'

Alpine.data('formable', formable)

Alpine.start()